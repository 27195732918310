import React from 'react';
import {
    FaChartPie,
    FaHouseUser,
    FaKey,
    FaUserCog,
    FaUserFriends, FaUserPlus,
    FaUsers
} from "react-icons/all";
import WorkerListComponent from "../workerList/WorkerListComponent";
import ReservationList from "../reservationList/ReservationList";
import RoomList from "./RoomList";
import GuestList from "./GuestList";
import Dashboard from "./dashboard/Dashboard";

export const SidebarDataAdmin =  [
    {
        title: "Dashboard",
        icon: <FaChartPie/>,
        comp: <Dashboard/>,
        hrf: "/admin"
    },
    {
        title: "Rezerwacje",
        icon: <FaHouseUser/>,
        comp: <ReservationList/>,
        hrf: "/admin/rezerwacje"
    },
    {
        title: "Pokoje",
        icon: <FaKey/>,
        comp: <RoomList/>,
        hrf: "/admin/pokoje"
    },
    {
        title: "Goście",
        icon: <FaUsers/>,
        comp: <GuestList/>,
        hrf: "/admin/goscie"
    },
    {
        title: "Pracownicy",
        icon: <FaUserCog/>,
        comp: <WorkerListComponent/>,
        hrf: "/admin/pracownicy"
    }, {
        title: "Przeładowanie",
        icon: <FaUserFriends/>,
        comp: <WorkerListComponent/>,
        hrf: "/admin/przeladowanie"
    },
    {
        title: "Nowa rezerwacja",
        icon: <FaUserPlus/>,
        hrf: "/"
    },

]


export default SidebarDataAdmin;