import React, {useEffect, useState} from 'react';
import {Modal, Button, Row, Col, Form, Dropdown, Spinner} from "react-bootstrap";
import RoomService from "../../service/RoomService";
import ReservationService from "../../service/ReservationService";
import useToastContext from "../nativeComponents/useToastContext";
import moment from "moment";


const Counter = ({value, places, onIncrement, onDecrement, hideIncrement}) => {
    return (
        <span>
            <Button
                disabled={value < 2}
                variant="counter"
                onClick={() => {
                    onDecrement();
                }}
            >
                -
            </Button>
            <span> {value} / {places} </span>
                <Button
                    disabled={hideIncrement}
                    variant="counter"
                    onClick={() => {
                        onIncrement();
                    }}
                >
                    +
                </Button>
        </span>
    );
};

const EditRoomsModal = (props) => {

    const addToast = useToastContext();
    const [searchValue, setSearchValue] = useState('');

    const [loading, setLoading] = useState(false)

    const [roomsModalShow, setRoomsModalShow] = useState(false);

    const [rooms, setRooms] = useState([]);
    const [chosenRooms, setChosenRooms] = useState([])

    const [form, setForm] = useState(props.data)

    const [dateError, setDateError] = useState(false);

    const [counters, setCounters] = useState([]);
    const sum = counters.reduce((acc, item) => acc + item, 0);

    let arrivalDetails = {
        numberOfPeople: form.howManyPeople,
        reservationDayStart: form.reservationStart,
        reservationDayEnd: form.reservationEnd
    }

    const fetchData = async () => {
        const {data} = await RoomService.getAvailableAdminRooms2(props.data.id, arrivalDetails);
        // console.log(data)
        return data;
    }


    useEffect(() => {
        if (props.show) {
            setLoading(true)

            fetchData().then(data => {
                setLoading(false)
                setRooms(data)
                let cr = data.filter(i => form.roomNumbers.includes(i.roomNumber));
                setChosenRooms(cr)
                setCounters(cr.map(a => a.roomProduct.numberOfPeopleInRoom));

            }).catch((e) => {
                console.log("błąd ładowania danych")
            });

        }

    }, [props])


    const addRooms = () => {
        setRoomsModalShow(true)
    }

    const addRoom = (id, rn) => {
        const found = rooms.find((item) => {
            return item.roomNumber === rn
        })
        setChosenRooms([...chosenRooms, found])
        setCounters([...counters, 1]);
    }
    const removeRoom = (id, rn) => {
        const arr = chosenRooms.filter((i) => i.roomNumber !== rn)
        setChosenRooms(arr)
        counters.splice(id, 1);
    }

    const handleForm = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        setForm({...form, [name]: value})


        // if (moment(form.reservationEnd).diff(moment(value), 'days') < 1) {
        //     setForm({...form, 'reservationEnd': value})
        //     setForm({...form, 'reservationStart': value})
        // }
        // if (moment(form.reservationEnd).diff(moment(form.reservationStart), 'days') < 1) {
        //     let end = moment(moment(form.reservationStart).add(1, 'd')).format('YYYY-MM-DD')
        //     setForm({...form, 'reservationEnd': end})
        // }
        console.log(value)

        if (name === 'reservationStart' || name === 'reservationEnd') {

            if (value !== props.data.reservationStart || value !== props.data.reservationEnd) {
                setChosenRooms([])
                setLoading(true)
                fetchData().then(data => {
                    setLoading(false)
                    setRooms(data)
                }).catch((e) => {
                    console.log("błąd ładowania danych")
                });
                console.log('zmieniono')
                setCounters([])
                setDateError(true)
            }
        }
    }

    const editRoomsHandler = () => {

        const roomList = chosenRooms.map((item, id) => {
            return {room: item, numberOfPeople: counters[id]};
        });

        let nights = moment(form.reservationEnd).diff(moment(form.reservationStart), 'days')

        const newReservation = {
            firstName: form.firstName,
            lastName: form.lastName,
            howManyPeople: sum,
            phoneNumber: form.phoneNumber,
            reservationStart: form.reservationStart,
            reservationEnd: form.reservationEnd,
            // amountToPay: 900,
            email: form.email,
            extraInformation: props.data.extraInfo,
            nights: nights,
            roomProducts: roomList,
        }

        // console.log(newReservation)

        let reservationId = props.data.id;

        ReservationService.updateFullReservation(reservationId, newReservation).then(r => {
            console.log(r)
            ReservationService.deleteReservation(props.data.id).then(r => {
                console.log(r)
                addToast({text: "Pomyślnie zamieniono rezerwacje!", variant: true})
                props.reload()
                props.onHide()
            }).catch(e => {
                console.error(e)
                addToast({text: "Operacja zakończona niepowodzeniem!", variant: false})
                props.onHide()
            })


        }).catch(e => {
            console.error(e)
            addToast({text: "Operacja zakończona niepowodzeniem!", variant: false})
            props.onHide()
        })
    }


    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-start fw-normal" id="contained-modal-title-vcenter">
                    <strong>Edycja rezerwacji nr {props.data.id}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="d-flex align-items-baseline gap-3">
                    <h5 className="mb-3 fw-bold">Pokoje</h5>
                    <a type="button" className="link-dark text-decoration-underline"
                       onClick={addRooms}>Dodaj</a>
                </div>
                {
                    !rooms ? <div>brak</div> :
                        <>
                            <div className="mb-1">
                                <strong>{chosenRooms.length} pokoje · {sum} gości</strong>
                            </div>
                            <div className="d-flex flex-column gap-3">
                                {
                                    dateError && <div>Zmieniono daty rezerwacji, prosze wybrać nowe pokoje</div>
                                }
                                {
                                    loading ? <Spinner className="align-self-center" animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                    chosenRooms.map((room, key) => {
                                        return (
                                            <div
                                                className="border mx-0 py-2 rounded-3 row"
                                                key={key}>
                                                <div className="col-12 col-lg-7 d-flex gap-3 align-items-center">
                                                    <div>
                                                        <img style={{maxHeight: "60px", maxWidth: "100px"}}
                                                             className="img-fluid rounded-3"
                                                             src={`/${room.imgUrl}`}
                                                             alt=""/>
                                                    </div>
                                                    <div>
                                                        <div style={{fontWeight: "500"}}
                                                             className="">{room.description}</div>
                                                        <div><small>Numer pokoju: {room.roomNumber}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-10 col-lg-3 d-flex align-items-center justify-content-end">
                                                    <Counter
                                                        places={room.placesLeft}
                                                        value={counters[key]}
                                                        hideIncrement={counters[key] >= room.placesLeft}
                                                        onIncrement={() => {
                                                            const countersCopy = [...counters];
                                                            countersCopy[key] += 1;
                                                            setCounters(countersCopy);
                                                        }}
                                                        onDecrement={() => {
                                                            const countersCopy = [...counters];
                                                            countersCopy[key] -= 1;
                                                            setCounters(countersCopy);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-2 col-lg-2 d-flex align-items-center justify-content-end">
                                                    <a onClick={() => removeRoom(key, room.roomNumber)} type="button"
                                                       className="link-danger">Usuń</a>
                                                </div>


                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                }

                <h5 className="mt-5 mb-3 fw-bold">Szczegóły rezerwacji</h5>

                <Row>
                    <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik101"
                        className="position-relative"
                    >
                        <Form.Label>Imię</Form.Label>
                        <Form.Control
                            type="text"
                            value={form.firstName}
                            name="firstName"
                            onChange={handleForm}
                        />
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik102"
                        className="position-relative"
                    >
                        <Form.Label>Nazwisko</Form.Label>
                        <Form.Control
                            type="text"
                            value={form.lastName}
                            name="lastName"
                            onChange={handleForm}
                        />
                    </Form.Group>
                </Row>
                <Row className="mt-4">
                    <Form.Group
                        as={Col}
                        md="6"
                        className="position-relative"
                    >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            value={form.email}
                            name="email"
                            onChange={handleForm}
                        />
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        md="6"
                        className="position-relative"
                    >
                        <Form.Label>Telefon</Form.Label>
                        <Form.Control
                            type="text"
                            value={form.phoneNumber}
                            name="phoneNumber"
                            onChange={handleForm}
                        />
                    </Form.Group>
                </Row>

                <h5 className="mt-5 mb-3 fw-bold">Daty rezerwacji</h5>
                <p className="text-danger"><strong>W przypadu zmiany dat, należy wybrać pokoje ponownie</strong></p>

                <Row>
                    <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik103"
                        className="position-relative"
                    >
                        <Form.Label>Zameldowanie</Form.Label>
                        <Form.Control
                            onKeyDown={(event) => {
                                event.preventDefault();
                            }}
                            type="date"
                            value={form.reservationStart}
                            name="reservationStart"
                            onChange={handleForm}
                        />
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik104"
                        className="position-relative"
                    >
                        <Form.Label>Wymeldowanie</Form.Label>
                        <Form.Control
                            type="date"
                            onKeyDown={(event) => {
                                event.preventDefault();
                            }}
                            value={moment(form.reservationEnd).diff(moment(form.reservationStart), 'days') < 1 ? setForm({...form, 'reservationEnd': moment(moment(form.reservationStart).add(1, 'd')).format('YYYY-MM-DD')}) : form.reservationEnd}
                            name="reservationEnd"
                            onChange={handleForm}
                            min={moment(moment(form.reservationStart).add(1, 'd')).format('YYYY-MM-DD')}
                        />
                    </Form.Group>
                </Row>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Anuluj</Button>
                <Button disabled={chosenRooms.length < 1} onClick={editRoomsHandler}>Zatwierdź</Button>
            </Modal.Footer>
            <RoomsModal
                rooms={rooms}
                addRoom={addRoom}
                show={roomsModalShow}
                onHide={() => setRoomsModalShow(false)}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                form={form}
                data={props.data}
                chosenRooms={chosenRooms}
                loading={loading}
            />
        </Modal>
    );
};

export default EditRoomsModal;

function RoomsModal(props) {

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Dodaj pokoje
                </Modal.Title>
            </Modal.Header>
            <div className="border-bottom px-3 py-3 d-grid">
                <Form.Control
                    autoFocus
                    placeholder="Szukaj..."
                    onChange={(e) => props.setSearchValue(e.target.value)}
                    value={props.searchValue}
                />
            </div>
            <div className="border-bottom px-3 py-1">
                Dostępne pokoje od <strong>{props.form.reservationStart}</strong> do <strong>{props.form.reservationEnd}</strong>
            </div>
            <Modal.Body>
                <div className="d-flex flex-column gap-4 px-2">
                    {
                        props.loading ? <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                        props.rooms.length === 0 ? <div>brak</div> :
                            props.rooms.filter(s => !props.chosenRooms.map(a => a.roomNumber).includes(s.roomNumber) && (s.description.toLowerCase().includes(props.searchValue) || s.roomNumber.toString().includes(props.searchValue))).map((room, key) => {
                                    return (
                                        <div className="cursor-pointer"
                                             onClick={() => props.addRoom(key, room.roomNumber)}
                                             key={key}
                                        >
                                            <div className="d-flex gap-3 align-items-center">
                                                <div>
                                                    <img style={{maxHeight: "40px", maxWidth: "70px"}}
                                                         className="img-fluid rounded-3" src={`/${room.imgUrl}`} alt=""/>
                                                </div>
                                                <div>
                                                    <div style={{fontWeight: "500"}} className="">{room.description}</div>
                                                    <div><small>Numer pokoju: {room.roomNumber} · Liczba
                                                        miejsc {room.placesLeft} / {room.forHowManyPeople}</small></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Anuluj</Button>
            </Modal.Footer>
        </Modal>
    );
}