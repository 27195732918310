import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import './i18n'

ReactDOM.render(<App />, document.getElementById('root'));
