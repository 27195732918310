import axios from "axios";
import authHeader from "./AuthHeader";
import {ENVIRONMENT} from "../config/environment";

const API_URL = `${ENVIRONMENT.HOST_ADDRESS}${ENVIRONMENT.PORT}`


class ReservationService {

    createReservation(reservation) {
        return axios.post(API_URL + "/api/reservations/", reservation)
    }

    createAdminReservation(reservation) {
        return axios.post(API_URL + "/api/reservations/admin", reservation, {headers: authHeader()})
    }

    getComingReservations(date) {
        return axios.post(API_URL + "/api/reservations/coming", date, {headers: authHeader()})
    }

    getTodayReservations(date) {
        return axios.post(API_URL + "/api/reservations/today", date, {headers: authHeader()})
    }

    getCurrentGuests(date) {
        return axios.post(API_URL + "/api/reservations/guests", date, {headers: authHeader()})
    }

    getReservationsOverview(date) {
        return axios.post(API_URL + "/api/reservations/overview", date, {headers: authHeader()})
    }

    getReservations(number, size, parameters) {
        number -= 1;
        return axios.post(API_URL + "/api/reservations/search?page=" + number + "&size=" + size, parameters, {headers: authHeader()});
    }

    deleteReservation(id) {
        return axios.delete(API_URL + "/api/reservations/" + id, {headers: authHeader()})
    }

    getReservationById(id) {
        return axios.get(API_URL + "/api/reservations/" + id, {headers: authHeader()})
    }

    updateReservation(reservation, id) {
        return axios.put(API_URL + "/api/reservations/" + id, reservation, {headers: authHeader()})
    }

    confirmPayment(id, update) {
        return axios.patch(API_URL + "/api/reservations/" + id, update, {headers: authHeader()})
    }

    exportExcel(guests) {
        return axios.post(API_URL + "/api/reservations/excel", guests, {headers: authHeader()})

    }

    updateRooms(roomId, roomList) {
        return axios.put(API_URL + "/api/reservations/rooms/" + roomId, roomList, {headers: authHeader()})
    }

    updatePayment(id, amount) {
        return axios.patch(API_URL + "/api/reservations/payment/" + id, amount, {headers: {...authHeader(), "Content-Type": "application/json"}})
    }

    arrivedStatus(id, status) {
        return axios.patch(API_URL + "/api/reservations/arrivedStatus/" + id, status, {headers: authHeader()})
    }

    updateFullReservation(id, reservation) {
        return axios.put(API_URL + "/api/reservations/full-edition/" + id, reservation, {headers: authHeader()})
    }

}

export default new ReservationService();
